import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/Seo"
import { Col, Row, Container } from "react-bootstrap"
import PortableText from "react-portable-text"

const Policy = ({ data }) => {
  const { title, header, _rawBody, releaseDate } = data.sanityPolicy
  const serializer = {
    strong: props => <strong className="text-white" {...props} />,
    highlight: props => <mark {...props} />,
    reversedEmail: props => <mark className="ltrTextReverse" {...props} />,
  }
  return (
    <Layout>
      <Seo
        title={title}
        keywords={[
          "психолог",
          "барселона",
          "степаненко",
          "александр",
          "зависимость",
          "алкоголизм",
          "наркомания",
          "помощь",
        ]}
      />
      <Container fluid="lg">
        <Row>
          <Col
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="py-5 arrowbackground"
          >
            <div className="text-white">
              <h1 className="display-6 uk-heading-divider">{header}</h1>
            </div>
            <PortableText
              className="uk-text-meta p-3"
              content={_rawBody}
              serializers={serializer}
            />
            <p className="text-info text-right">
              Дата публикации и обновления статьи <b>{releaseDate}</b>
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    sanityPolicy {
      title
      header
      description
      _rawBody(resolveReferences: { maxDepth: 10 })
      releaseDate(formatString: "DD-MM-YYYY")
    }
  }
`

export default Policy
